import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1 from "../../images/RUBICON-L-SERIES-30-DISK-SCRUBBER.png"
import Image2 from "../../images/rubicon3.png"
import Image3 from "../../images/rubicon3.png"
import Image4 from "../../images/rubicon3.png"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const L30DSPage = () => {
  return (
    <>
      <SEO title="Products" />
      <Helmet>
        <title>L30 Disk Scrubber</title>
        <description></description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              image1={Image1}
              image2={Image2}
              image3={Image3}
              image4={Image4}
              mobileLink="#demo-section"
              mobileTitle="L30 Disk"
            />
            <ProductDescription
              productTitle="L30 Disk Scrubber"
              productDescription="Lorem ipsum dolor amet activated charcoal bespoke post-ironic, vexillologist unicorn mixtape af plaid retro. Master cleanse kickstarter mlkshk quinoa, normcore fingerstache single-origin coffee. Organic slow-carb fam 3 wolf moon, truffaut iceland ramps vaporware vice selvage. Pop-up intelligentsia coloring book gentrify. Skateboard glossier gastropub irony umami microdosing hell of salvia kitsch."
              link="#demo-section"
              brochure="../../timberline-walk-behind-sm.pdf"
              tech="../../tl-l-series-tech-specs-v21263.pdf"
              parts="https://www.dropbox.com/s/d4hfjc714ofn6x6/t-l-series-p-en.pdf?dl=0"
              operator="https://www.dropbox.com/s/fesp0mxj3v2ki7u/t-l-series-op-en.pdf?dl=0"
              hood="../../timberline-underthehood.pdf"
              pm="../../L-Series-PM.pdf"
              mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
          </div>
          <div id="demo-section"></div>
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default L30DSPage
